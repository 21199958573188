#container-main {
  height: 100%;
  width: inherit;
  background-color: transparent;
  display: flex;

  .content {
    flex: 5;
    padding: 20px;
    margin-left: 25vmin;
    overflow: auto;
    color: #fff;
  }

  // @media screen and (max-width: 600px) {
  //   .side {
  //     background-color: pink;
  //   }
  // }
}
